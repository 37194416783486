import { HttpErrorResponse } from '@angular/common/http';
import { Action, createActionGroup, props } from '@ngrx/store';
import { catchError, from, Observable, OperatorFunction } from 'rxjs';
import { ZodError } from 'zod';

export const ErrorActions = createActionGroup({
  source: 'Error Handling',
  events: {
    'Zod Error': props<Pick<ZodError, 'errors' | 'message'> & { notificationMessage: string }>(),
    'Http Error': props<Pick<HttpErrorResponse, 'message' | 'status' | 'statusText'> & { notificationMessage: string }>(),
    'Generic Error': props<Pick<Error, 'message' | 'stack'> & { notificationMessage: string }>(),
  },
});

export function errorHandler(error: Error, notificationMessage: string, ...additionalActions: Action[]): Observable<Action> {
  if (error instanceof HttpErrorResponse) {
    return from([
      ErrorActions.httpError({
        message: error.message,
        status: error.status,
        statusText: error.statusText,
        notificationMessage,
      }),
      ...additionalActions,
    ]);
  } else if (error.cause instanceof ZodError) {
    return from([
      ErrorActions.zodError({
        message: error.message,
        errors: error.cause.errors,
        notificationMessage,
      }),
      ...additionalActions,
    ]);
  } else {
    return from([
      ErrorActions.genericError({
        message: error.message,
        stack: error.stack,
        notificationMessage,
      }),
      ...additionalActions,
    ]);
  }
}

export function handleError<T>(notificationMessage: string, ...additionalActions: Action[]): OperatorFunction<T, Action | T> {
  return catchError((error: Error) => errorHandler(error, notificationMessage, ...additionalActions));
}
