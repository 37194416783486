import { inject } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

export function injectActions(): Actions {
  return inject<Actions>(Actions);
}

export function injectStore(): Store {
  return inject<Store>(Store);
}
